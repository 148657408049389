import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from '../locales/translations'

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,
    fallbackNS: 'translations',

    lng: typeof window !== `undefined` ? (window.location.pathname.indexOf('fr-ca') < 0 ? 'en' : 'fr') : 'en',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;