import React from "react"
import { useTranslation } from "react-i18next";
import styles from "../styles/modules/header.module.scss"

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation("header");

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);

    if (typeof window !== `undefined`) {
      window.location.pathname = `${i18n.language === 'fr' ? '/fr-ca' : ''}${window.location.pathname.replace('/fr-ca', '')}`;
    }
  };

  return (
    <button
      className={styles.menu__item}
      onClick={
        () => changeLanguage(i18n.language === 'en' ? 'fr' : 'en')
      }
    >
      {t("switch_language")}
    </button>
  )
}

export default LanguageSwitcher
