import React from "react"
import { useTranslation } from 'react-i18next'
import styles from "../styles/modules/banner.module.scss"

const Banner = () => {
  const { t } = useTranslation('banner')

  return (
    <aside className={styles.banner}>
      <i className="fal fa-ruler"></i>
      <h2>{t('title')}</h2>
      <p dangerouslySetInnerHTML={
          {__html: t('content', {interpolation: {escapeValue: false}})}
      }></p>
    </aside>
  )
}

export default Banner
