import React from "react"
import "../utils/i18n";
import Banner from "../components/banner"

import styles from "../styles/modules/footer.module.scss"

const Footer = () => {
  return (
    <>
      <Banner />
      <footer className={styles.footer}>
        <div className={styles.social}>
          <a href="https://www.facebook.com/Hoolaloom.creation/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f" title="Facebook"></i>
          </a>
          <a href="https://www.instagram.com/hoolaloom/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram" title="Instagram"></i>
          </a>
        </div>
        <a className={styles.email} href="mailto:contact@hoolaloom.com" target="_blank" rel="noopener noreferrer">
          contact@hoolaloom.com
        </a>
        <div className={styles.copyright}>
          © {new Date().getFullYear()} Hoolaloom
        </div>
      </footer>
    </>
  )
}

export default Footer
